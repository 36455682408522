import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';

const CookiePolicy = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted the cookies
    const cookieAccepted = localStorage.getItem('cookieAccepted');
    if (!cookieAccepted) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    setVisible(false);
    // Set a flag in local storage to remember the user's choice
    localStorage.setItem('cookieAccepted', 'true');
  };

  if (!visible) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        maxWidth: '800px',
        width: '100%',
        backgroundColor: '#ffffff',
        color: '#000',
        padding: '16px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: 'Abel, sans-serif',
        animation: 'slideUp 0.5s ease-out',
      }}
    >
      <Typography variant="body2" sx={{ fontFamily: 'Abel, sans-serif' }}>
        We use cookies to enhance your browsing experience. By continuing to use this site, you accept our use of cookies.{' '}
        <a href="/privacy-policy" style={{ color: '#146C94', textDecoration: 'underline' }}>Learn more</a>.
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#146C94',
          color: '#ffffff',
          marginLeft: '16px',
          '&:hover': {
            backgroundColor: '#125678',
          },
          fontFamily: 'Abel, sans-serif',
        }}
        onClick={handleAccept}
      >
        Accept
      </Button>
    </Box>
  );
};

// Add this to your global CSS or the component's CSS-in-JS styling
const slideInStyles = `
@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
  }
  to {
    transform: translate(-50%, 0);
  }
}`;

// Insert the styles into the document
document.head.insertAdjacentHTML(
  'beforeend',
  `<style>${slideInStyles}</style>`
);

export default CookiePolicy;
