import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { ReactComponent as LogoSvg } from "./../images/app-bar-logo-dark.svg";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from 'swiper/modules';
import "./../style/vehicle.css";
import Sidebar from "./../components/sidebar";

const Vehicle = () => {
  const topRef = useRef(null);
  
  const { id } = useParams();
  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  
  useEffect(() => {
    const fetchVehicle = async () => {
      setLoading(true);
      const docRef = doc(db, 'vehicles', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setCar({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.log("No such document!");
      }
      setLoading(false);
    };
    fetchVehicle();
  }, [id]);

  useEffect(() => {
    if (!loading && car && topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [loading, car]);

  const openModal = (index) => {
    setActiveImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (!car) {
    return <p>Vehicle not found</p>;
  }

  return (
    <>
      <div ref={topRef}>
        <Sidebar />
      </div>
      <HelmetProvider>
        <Helmet>
          <title>{`${car.make} ${car.model} - Swift Bros Automotive`}</title>
          <meta name="description" content={car.description} />
          <meta name="keywords" content={`${car.make}, ${car.model}, ${car.year}, ${car.odometer}, ${car.engineSize}, ${car.price}`} />
          <link rel="canonical" href={`https://swiftbrosauto.com/vehicle/${car.id}`} />
        </Helmet>
      </HelmetProvider>

      <div className="vehicle-detail fade-in">
        <div className="vehicle-info">
          <div className="vehicle-images">
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              pagination={{ clickable: true }}
              navigation={true}
              modules={[Pagination, Navigation]}
              loop={true}
              speed={600}
              className="vehicle-swiper"
            >
              {car.images.map((image, index) => (
                <SwiperSlide key={index}>
                  <div className="image-container">
                    <img 
                      src={image} 
                      alt={`${car.make} ${car.model}`} 
                      onClick={() => openModal(index)} 
                      className="fade-in"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <h1 className="vehicle-title">{car.make} {car.model}</h1>
          <div className="pricing-info">
            <p>Price: {car.price}</p>
            <p className="smaller">GST included</p>
          </div>
          <div className="vehicle-details-container">
            <div className="vehicle-details">
              <table>
                <tbody>
                  <tr>
                    <td className="label">Make:</td>
                    <td className="value">{car.make}</td>
                  </tr>
                  <tr>
                    <td className="label">Model:</td>
                    <td className="value">{car.model}</td>
                  </tr>
                  <tr>
                    <td className="label">Year:</td>
                    <td className="value">{car.year}</td>
                  </tr>
                  <tr>
                    <td className="label">Odometer:</td>
                    <td className="value">{car.odometer}km</td>
                  </tr>
                  <tr>
                    <td className="label">Engine Size:</td>
                    <td className="value">{car.engineSize}cc</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h3>Vehicle Description:</h3>
          <div className="description fade-in" dangerouslySetInnerHTML={{ __html: car.description }}></div>
          <div className="finance-info fade-in">
            <p>WANT TO HEAR ABOUT FINANCE? GIVE US A CALL!</p>
          </div>
        </div>

        <section className="text-section">
          <h3>A BETTER WAY OF BUYING.</h3>
          <section className="logo-banner-home">
            <LogoSvg className="logo-svg-home" />
          </section>
        </section>
      </div>
      {showModal && (
        <div className="modal" onClick={closeModal}>
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            initialSlide={activeImageIndex}
            className="swiper-container"
            loop={true}
            speed={600}
          >
            {car.images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="modal-content-wrapper">
                  <img src={image} alt={`${car.make} ${car.model}`} className="modal-content" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default Vehicle;
