import React from "react";
import "./../style/submission.css"; 
import { ReactComponent as LogoSvg } from "./../images/app-bar-logo-dark.svg";

class Submission extends React.Component {
    handleGoBack = () => {
      window.location.href = "/";
    };
  
    handleCarsForSale = () => {
      window.location.href = "/vehicles";
    };

  render() {
    return (
      <div>
        <h1 className="submission-title">SUCCESS!</h1>
        <h2 className="submission-text">We will be in contact soon.</h2> 
        <div className="buttons">
            <button onClick={this.handleGoBack} className="button">Go Back</button>
            <button onClick={this.handleCarsForSale} className="button">Cars for Sale</button>
        </div>
        <section className="text-section">
          <h3>A Name You Can Trust</h3>
          <section className="logo-banner-home">
            <LogoSvg className="logo-svg-home" />
          </section>
        </section>
      </div>
    );
  }
}

export default Submission;
