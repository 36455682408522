import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./../style/new-home.css";
import { ReactComponent as LogoSvg } from "./../images/app-bar-logo-dark.svg";
import headerImage from "./../images/header-car.webp";

import coupe from "./../images/cars/coupe.webp";
import hatchback from "./../images/cars/hatchback.webp";
import sedan from "./../images/cars/sedan.webp";
import suv from "./../images/cars/suv.webp";
import van from "./../images/cars/van.webp";
import wagon from "./../images/cars/wagon.webp";
import ute from "./../images/cars/ute.webp";

import brandImageLeft from "./../images/home-first-left.webp";
import brandImageRight from "./../images/home-first-right.webp";
import brandImageSecondLeft from "./../images/home-second-left.webp";
import Gallery from "../components/gallery";
import { Helmet, HelmetProvider } from "react-helmet-async";



const carImages = [
  { title: "HATCHBACK", type: "hatchback", src: hatchback },
  { title: "SEDAN", type: "sedan", src: sedan },
  { title: "SUV", type: "suv", src: suv },
  { title: "VAN", type: "van", src: van },
  { title: "UTE", type: "ute", src: ute },
  { title: "WAGON", type: "wagon", src: wagon },
  { title: "COUPE", type: "coupe", src: coupe },
];

const suggestionsData = [
  { title: "What kinds of finance options do you offer?", link: "/faq" },
  { title: "How do i apply for finance?", link: "/faq" },
  { title: "Can i test drive before buying?", link: "/faq" },
  { title: "can i trade in my old vehicle?", link: "/faq" },
  { title: "How do i trade in my old vehicle?", link: "/faq" },
  { title: "FAQ", link: "/faq" },
  { title: "Contact", link: "/contact" },
  { title: "Sell Your Car", link: "/sell-your-car" },
  { title: "Hatchback", link: "/vehicles/hatchback" },
  { title: "Sedan", link: "/vehicles/sedan" },
  { title: "SUV", link: "/vehicles/suv" },
  { title: "Van", link: "/vehicles/van" },
  { title: "Ute", link: "/vehicles/ute" },
  { title: "Wagon", link: "/vehicles/wagon" },
  { title: "Coupe", link: "/vehicles/coupe" },
];

const Home = () => {
  const [searchInput, setSearchInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchInput(value);

    if (value) {
      const filteredSuggestions = suggestionsData.filter((suggestion) =>
        suggestion.title.toLowerCase().includes(value)
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (link) => {
    navigate(link);
    setSearchInput("");
    setSuggestions([]);
  };

  const handleSearch = () => {
    if (searchInput.trim()) {
      navigate(`/vehicles?search=${encodeURIComponent(searchInput.trim())}`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Car Dealership | Swift Bros Automotive</title>
          <meta
            name="description"
            content="Swift Bros Automotive - Your Local Vehicle Experts"
          />
          <meta
            name="keywords"
            content="cars, vehicles, buy, sell, trade, affordable, reliable"
          />
          <link rel="canonical" href="https://swiftbrosauto.com" />
        </Helmet>
      </HelmetProvider>
      <div className="background">
        <header className="banner">
          <div className="options">
            <Link to="/vehicles" className="option-link">
              BUY
            </Link>
            <div className="divider"></div>
            <Link to="/sell-your-car" className="option-link">
              SELL
            </Link>
            <div className="divider"></div>
            <Link to="/sell-your-car" className="option-link">
              TRADE
            </Link>
          </div>

          <section className="img-container">
            <img src={headerImage} alt="Toyota Hilux" className="full-width-image" width="600" height="400" />
          </section>
        </header>

        <main className="content">
          <div className="centered-big-title">
            <h2>Car Dealership</h2>
          </div>

          <div className="search-section margin-top">
            <input
              type="text"
              className="search-bar"
              placeholder="QUICK SEARCH"
              value={searchInput}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
            />
            <button onClick={handleSearch} className="search-button">Search</button>
            {suggestions.length > 0 && (
              <ul className="suggestions-list">
                {suggestions.map((suggestion, index) => (
                  <li key={index} onClick={() => handleSuggestionClick(suggestion.link)}>
                    {suggestion.title}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="centered-small-title">
            <h3>Know what you're after?</h3>
          </div>

          <Gallery className="gallery-section" carImages={carImages} />

          <div className="white-background">
            <section className="brand-section">
              <div className="brand-content-container">
                <div className="brand-divider"></div>
                <div className="row">
                  <div className="image-container">
                    <img
                      src={brandImageLeft}
                      alt="Left"
                      className="brand-image"
                      loading="lazy"
                      width="600"
                      height="400"
                    />
                  </div>
                  <div className="text-container">
                    <p className="brand-text">
                      Your Local Vehicle Experts
                      Introducing Swift Bros' - a revolution in affordable and reliable vehicles. Since its inception in 2022, this dealership has been committed to offering unbeatable prices without compromising on quality. Unlike Facebook Marketplace, where buyers can never be sure of the source or quality of their purchases, Swift Bros' is a trusted and reputable dealer that puts customer satisfaction first.
                    </p>
                  </div>
                </div>
                <div className="brand-divider"></div>
                <div className="row reverse">
                  <div className="image-container">
                    <img
                      src={brandImageRight}
                      alt="Right"
                      className="brand-image brand-image-reverse"
                      loading="lazy"
                      width="600"
                      height="400"
                    />
                  </div>
                  <div className="text-container">
                    <p className="brand-text">
                      With an extensive range of vehicles to choose from, including options for first-time buyers and experienced vehicle owners alike, there's something for everyone at Swift Bros'
                      But it's not just about the cars - our financing options are flexible and affordable, making it easier than ever to get behind the wheel of your dream vehicle. Say goodbye to muck arounds and hello to a better way of buying with Swift Bros'.
                    </p>
                  </div>
                </div>
                <div className="brand-divider"></div>
                <div className="row">
                  <div className="image-container">
                    <img
                      src={brandImageSecondLeft}
                      alt="Left"
                      className="brand-image brand-image-reverse"
                      loading="lazy"
                      width="600"
                      height="400"
                    />
                  </div>
                  <div className="text-container">
                    <p className="brand-text">
                      Since our start in 2022, Swift Bros Automotive has been dedicated to exceptional service. Our friendly team ensures a smooth car-buying experience. Based in Wellington and extending to the South Island, our Wellington branch, Run by Kyle Swift and Harry Ludemann in Wellington and Jack Swift in Christchurch. At Swift Bros, buying a car is an experience, not just a transaction.
                    </p>
                  </div>
                </div>
                <div className="brand-divider"></div>
              </div>
            </section>
          </div>

          <section className="text-section">
            <h3>A Name You Can Trust</h3>
            <section className="logo-banner-home">
              <LogoSvg className="logo-svg-home" />
            </section>
            <p>
              Swift Bros Automotive was formed by two Christchurch brothers who each
              have a strong passion for cars. After their fair share of bad cars
              and deceptive marketplace sellers, they decided to make a change in
              the way people buy and sell their cars by offering competitive prices
              and their reassurance of the family name Jack & Kyle Swift. The Swift
              Brothers.
            </p>
          </section>
        </main>
      </div>
    </>
  );
};

export default Home;
