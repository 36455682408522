import React from "react";
import "./../style/faq.css"; 
// import { ReactComponent as LogoSvg } from "./../images/app-bar-logo.svg";

class FAQ extends React.Component {
  render() {
    return (
      <div>
        <h1 className="faq-title">FAQ</h1>
        <div className="faq-section">
          <div className="faq-list">
            <div className="faq-item">
              <h3 className="question">WHAT KINDS OF FINANCING OPTIONS DO YOU OFFER?</h3>
              <p className="answer">
                Our financing services are designed to meet the diverse needs of our clients. We understand that everyone has different financial situations, which is why we offer flexible payment options for all budgets. Whether you prefer monthly or bi-weekly payments, we can accommodate your needs and ensure that you have a payment plan that works for you. Our third-party finance company provides a range of options, including low-interest rates and extended payment terms to make it easier for you to manage your finances.
                <br /> <br />
                With our flexible payment options, you can get the financing you need without worrying about breaking the bank.
              </p>
            </div>
            <div className="faq-item">
              <h3 className="question">HOW CAN I APPLY FOR FINANCE?</h3>
              <p className="answer">
                Applying for finance is now easier than ever. See a vehicle you like? just give us a call and we can get finance arranged for you!
              </p>
            </div>
            <div className="faq-item">
              <h3 className="question">CAN I TEST A VEHICLE BEFORE BUYING IT?</h3>
              <p className="answer">
                Absolutely! Give us a call or email to arrange a booking.
              </p>
            </div>
            <div className="faq-item">
              <h3 className="question">CAN I TRADE IN MY OLD VEHICLE?</h3>
              <p className="answer">
                At our dealership, we take pride in providing our customers with a fair and honest valuation of their vehicles. Unlike many of our competitors, we do not rely on computer-generated algorithms to determine the value of your car. Instead, we independently assess each vehicle that is brought to us, taking into account its unique features and condition. We understand that selling a car can be a stressful and overwhelming process.
                <br /> <br />
                That's why we strive to make it as easy and straightforward as possible for our customers. Whether you are looking to trade in your vehicle for something new or simply need some extra cash in the bank, we are here to help. Our team of experienced professionals has years of expertise in the automotive industry. We use this knowledge to provide you with an accurate valuation that reflects the true market value of your car.
                <br /> <br />
                When you bring your vehicle to us, you can rest assured that you will receive a fair and competitive offer. We believe in treating our customers with respect and transparency throughout the entire process. So why settle for a lowball offer generated by a computer algorithm? Give us a call or fill out our “sell a vehicle” form today and experience the difference that independent valuation can make when it comes time to sell your car.
              </p>
            </div>
            <div className="faq-item">
              <h3 className="question">HOW DO I TRADE IN MY OLD VEHICLE?</h3>
              <p className="answer">
                To trade in your old vehicle for one of our other cars, please head to our “Sell A Car” page and select “Trade in my car” when completing the form. We will then independently value your car and talk you through our vehicles for sale within your budget.
              </p>
            </div>
            <div className="faq-item">
              <h3 className="question">Where can I find more information?</h3>
              <p className="answer">
                For more information, please visit our <a href="/privacy-policy" style={{ color: '#146C94', textDecoration: 'underline' }}>Privacy Policy</a> and <a href="/terms-and-conditions" style={{ color: '#146C94', textDecoration: 'underline' }}>Terms and Conditions</a> pages.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
