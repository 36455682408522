import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Link } from 'react-router-dom';
import './../style/gallery.css';
import { useEffect, useRef, useState } from 'react';

const Gallery = ({ carImages }) => {
  const swiperRef = useRef(null);

  useEffect(() => {
    const handleWheel = (e) => {
      const swiper = swiperRef.current.swiper;
      if (e.deltaY !== 0) {
        e.preventDefault(); // Prevent the default page scroll
        if (e.deltaY > 0) {
          swiper.slideNext();
        } else {
          swiper.slidePrev();
        }
      }
    };

    const swiperContainer = swiperRef.current;
    const mediaQuery = window.matchMedia('(max-width: 1024px)');

    if (mediaQuery.matches) {
      swiperContainer.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (mediaQuery.matches) {
        swiperContainer.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  // State to keep track of image loading
  const [loadedImages, setLoadedImages] = useState({});

  // Function to handle image load
  const handleImageLoad = (index) => {
    setLoadedImages((prevLoadedImages) => ({
      ...prevLoadedImages,
      [index]: true,
    }));
  };

  return (
    <section className="gallery-section">
      <Swiper
        ref={swiperRef}
        spaceBetween={10}
        centeredSlides={false}
        slidesPerView={1}
        keyboard={{ enabled: true }}
        breakpoints={{
          300: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
          1000: {
            slidesPerView: 7,
            spaceBetween: 10,
          },
        }}
      >
        {carImages.map((car, index) => (
          <SwiperSlide key={index}>
            <Link to={`/vehicles/${car.type}`} className="gallery-item-link" alt={car.title}>
              <div className="gallery-item">
                <img
                  src={car.src}
                  className="car-image"
                  aria-label="Car"
                  loading="lazy"
                  onLoad={() => handleImageLoad(index)}
                />
                <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
                <div className="car-title" style={{ display: loadedImages[index] ? 'block' : 'none' }}>
                  {car.title}
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="scroll-indicator">
        <div className="arrow left">&larr;</div>
        <div className="scroll-text">SCROLL</div>
        <div className="arrow right">&rarr;</div>
      </div>
    </section>
  );
};

export default Gallery;
