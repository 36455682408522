import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Sidebar from "./components/sidebar";
import Home from "./pages/home";
import ErrorPage from "./pages/404";
import FAQ from "./pages/faq";
import SellYourCar from "./pages/sell-your-car";
import Vehicles from "./pages/vehicles";
import MeetTheTeam from "./pages/meet-the-team";
import PrivacyPolicy from "./pages/privacy";
import Submission from "./pages/submission";
import Vehicle from "./pages/vehicle";
import AdminDashboard from "./pages/admin-dashboard";
import { AuthProvider, useAuth } from "./components/auth";
import Login from "./pages/login";
import Footer from "./components/footer";
import CookiePolicy from './components/cookie-policy';
import TermsAndConditions from './pages/terms-conditions';
import { Box } from '@mui/material';

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

function AppContent() {
  const location = useLocation();

  // Check if the current route is the Vehicle page
  const showSidebar = !location.pathname.startsWith('/vehicle') && !location.pathname.startsWith('/sell-your-car');

  return (
    <>
      {showSidebar && <Sidebar />}
      <Box sx={{ flexGrow: 1, width: "100%", height: "100vh", overflow: "auto" }}>
        <Routes>
          <Route index element={<Home />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="sell-your-car" element={<SellYourCar />} />
          <Route path="vehicles/:type?/:region?" element={<Vehicles />} />
          <Route path="vehicle/:id" element={<Vehicle />} />
          <Route path="meet-the-team" element={<MeetTheTeam />} />
          <Route path="success-submission" element={<Submission />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="login" element={<Login />} />
          <Route path="admin" element={<ProtectedRoute><AdminDashboard /></ProtectedRoute>} />
        </Routes>
        <Footer />
      </Box>
      <CookiePolicy />
    </>
  );
}

function ProtectedRoute({ children }) {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

export default App;
