import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyARhDdnr9te4Ck-2G9XlP-LAE3j64upJpE",
    authDomain: "swiftbrosite.firebaseapp.com",
    projectId: "swiftbrosite",
    storageBucket: "swiftbrosite.appspot.com",
    messagingSenderId: "982702362605",
    appId: "1:982702362605:web:bb5b7f061df9a38794a1e4",
    measurementId: "G-TPDPXZGT15"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
