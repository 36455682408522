import React, { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged, signInWithEmailAndPassword, signOut, setPersistence, browserLocalPersistence } from "firebase/auth";
import { auth } from "../firebase";

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [_blank, setLoading] = useState(true);

    useEffect(() => {
        // Set persistence to LOCAL to keep the user signed in across sessions
        setPersistence(auth, browserLocalPersistence)
            .then(() => {
                const unsubscribe = onAuthStateChanged(auth, (user) => {
                    setCurrentUser(user);
                    setLoading(false);
                });

                return () => unsubscribe();
            })
            .catch((error) => {
                console.error("Failed to set persistence:", error);
                setLoading(false);
            });
    }, []);

    const login = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

    const logout = () => {
        return signOut(auth);
    };

    if (_blank) {
        // Optionally, you can show a loading spinner or a placeholder here while checking auth state
        return <div>Loading...</div>;
    }

    return (
        <AuthContext.Provider value={{ currentUser, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthConsumer = AuthContext.Consumer;
