import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItemText,
  ListItemButton,
  Box,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const AppLayout = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <> 
      <AppBar
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 2, // Ensure AppBar is above the Drawer
          backgroundColor: "transparent",
          boxShadow: location.pathname === "/" ? "none" : "0px 2px 4px rgba(0, 0, 0, 0.1)", // Conditional box shadow
          
          position: "relative",
          width: "100%",
          top: 0,
          left: 0,
          "@media (max-width: 600px)": {
            height: "56px",
          },
          "@media (min-width: 600px)": {
            height: "64px",
          },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link to="/" style={{ textDecoration: "none" }}>
              <svg width="128" height="64" viewBox="0 0 128 32" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Swift Bros">
                <g clipPath="url(#clip0_330_59)">
                  <path d="M7.2792 30.3434C6.35416 31.0947 5.07556 31.4729 3.44825 31.4729C3.06806 31.4729 2.69272 31.4521 2.32222 31.4129C1.94929 31.3738 1.62964 31.319 1.36085 31.2564V30.6486C1.62964 30.7138 1.94929 30.766 2.32222 30.8051C2.69272 30.8469 3.07049 30.8651 3.44825 30.8651C4.85519 30.8651 5.97155 30.5442 6.79246 29.9051C7.61338 29.266 8.02505 28.3712 8.02505 27.2234C8.02505 26.3808 7.795 25.6425 7.33732 25.0008C6.87722 24.3616 6.06357 23.6521 4.89394 22.8773L3.51848 21.9538C2.00014 20.9364 1.24219 19.6999 1.24219 18.2416C1.24219 17.1251 1.66839 16.2251 2.52321 15.5469C3.37803 14.866 4.51617 14.5269 5.94007 14.5269C6.20886 14.5269 6.49461 14.5425 6.79489 14.5764C7.09516 14.6103 7.36396 14.6495 7.60127 14.699V15.2808C7.12664 15.1686 6.56483 15.1112 5.91585 15.1112C4.66631 15.1112 3.6783 15.3877 2.94941 15.9356C2.22293 16.486 1.85727 17.2634 1.85727 18.2651C1.85727 18.9277 2.01225 19.5042 2.31979 19.9895C2.62733 20.4747 3.14555 20.9599 3.87445 21.4451L5.24991 22.3686C6.10473 22.9347 6.78036 23.4695 7.2792 23.9703C7.77805 24.4712 8.13402 24.9825 8.34712 25.499C8.56022 26.0182 8.66677 26.5921 8.66677 27.2234C8.66677 28.5512 8.20425 29.5895 7.2792 30.3434Z" fill="#146C94" stroke="#146C94" strokeWidth="2" strokeMiterlimit="10"/>
                  <path d="M10.0204 14.626H10.6621L17.5661 30.3825L22.3826 20.039L20.1281 14.626H20.7214L27.4122 30.406L34.384 14.626H34.953L27.5285 31.4755H27.2911L22.6659 20.7695L17.6847 31.4755H17.4232L10.0204 14.626Z" fill="#146C94" stroke="#146C94" strokeWidth="2" strokeMiterlimit="10"/>
                  <path d="M37.5611 14.626H38.1544V31.3764H37.5611V14.626Z" fill="#146C94" stroke="#146C94" strokeWidth="2" strokeMiterlimit="10"/>
                  <path d="M42.6609 14.626H51.9841V15.2077H43.2542V21.5442H48.7585V22.126H43.2542V31.3764H42.6609V14.626Z" fill="#146C94" stroke="#146C94" strokeWidth="2" strokeMiterlimit="10"/>
                  <path d="M59.3626 15.2077H53.7881V14.626H65.5304V15.2077H59.9559V31.3764H59.3626V15.2077Z" fill="#146C94" stroke="#146C94" strokeWidth="2" strokeMiterlimit="10"/>
                  <path d="M73.0035 14.626H77.2025C78.7693 14.626 79.9704 14.9547 80.8083 15.6095C81.6461 16.2642 82.0651 17.2086 82.0651 18.4373C82.0651 19.846 81.4645 20.939 80.261 21.7138C81.4306 22.0529 82.3605 22.6555 83.0482 23.5216C83.736 24.3877 84.0798 25.379 84.0798 26.4955C84.0798 28.066 83.5543 29.2712 82.5034 30.1138C81.4524 30.9564 79.9534 31.3764 78.0089 31.3764H73.0035V14.626ZM77.5561 22.1755C78.8855 21.8521 79.8638 21.3825 80.4983 20.7668C81.1303 20.1512 81.4475 19.3764 81.4475 18.4373C81.4475 17.386 81.0843 16.5851 80.3554 16.0347C79.6265 15.4842 78.5756 15.2103 77.2001 15.2103H73.5943V23.1981L77.5561 22.1782V22.1755ZM82.0408 29.6781C82.9901 28.9347 83.4647 27.8729 83.4647 26.4981C83.4647 25.4781 83.1088 24.5599 82.3968 23.7434C81.6849 22.9268 80.7671 22.3634 79.6459 22.0555C79.1083 22.2982 78.5392 22.5016 77.9387 22.6634L73.5968 23.8295V30.7973H78.0089C79.7476 30.7973 81.094 30.4242 82.0408 29.6808V29.6781Z" fill="#146C94" stroke="#146C94" strokeWidth="2" strokeMiterlimit="10"/>
                  <path d="M87.6153 14.626H91.6473C93.34 14.626 94.6428 14.9782 95.5606 15.6825C96.4783 16.3868 96.936 17.386 96.936 18.6799C96.936 19.5851 96.7253 20.3547 96.3064 20.986C95.8875 21.6173 95.2119 22.1599 94.2771 22.6138L97.7182 31.379H97.0547L93.7565 22.8564C93.2019 23.0834 92.553 23.3025 91.8119 23.5112L88.2304 24.5077V31.379H87.6129V14.626H87.6153ZM91.5068 22.9999C92.7079 22.6608 93.662 22.3008 94.3643 21.9199C95.069 21.539 95.5751 21.0877 95.8826 20.5608C96.1902 20.0364 96.3452 19.4077 96.3452 18.6799C96.3452 17.579 95.9335 16.726 95.1126 16.1182C94.2892 15.5103 93.1341 15.2077 91.6497 15.2077H88.2328V23.8999L91.5068 23.0025V22.9999Z" fill="#146C94" stroke="#146C94" strokeWidth="2" strokeMiterlimit="10"/>
                  <path d="M100.982 27.5288C100.263 26.2088 99.9025 24.701 99.9025 23.0001C99.9025 21.2992 100.261 19.7706 100.982 18.461C101.702 17.1488 102.699 16.121 103.971 15.3775C105.244 14.634 106.702 14.261 108.347 14.261C109.991 14.261 111.47 14.634 112.734 15.3775C114.001 16.121 114.991 17.1488 115.711 18.461C116.43 19.7706 116.791 21.2862 116.791 23.0001C116.791 24.714 116.43 26.2088 115.711 27.5288C114.991 28.8488 113.999 29.8792 112.734 30.6227C111.468 31.3688 110.008 31.7392 108.347 31.7392C106.685 31.7392 105.242 31.3662 103.971 30.6227C102.697 29.8792 101.702 28.8462 100.982 27.5288ZM115.168 27.2862C115.839 26.0653 116.176 24.6358 116.176 23.0001C116.176 21.3645 115.839 19.9375 115.168 18.714C114.495 17.4932 113.57 16.541 112.393 15.8627C111.216 15.1819 109.867 14.8427 108.349 14.8427C106.831 14.8427 105.482 15.1819 104.305 15.8627C103.126 16.5436 102.201 17.4932 101.53 18.714C100.857 19.9375 100.522 21.3645 100.522 23.0001C100.522 24.6358 100.859 26.0627 101.53 27.2862C102.201 28.5097 103.126 29.4592 104.305 30.1375C105.482 30.8184 106.831 31.1575 108.349 31.1575C109.867 31.1575 111.216 30.8184 112.393 30.1375C113.57 29.4566 114.497 28.5071 115.168 27.2862Z" fill="#146C94" stroke="#146C94" strokeWidth="2" strokeMiterlimit="10"/>
                  <path d="M125.37 30.3435C124.445 31.0949 123.169 31.4731 121.539 31.4731C121.159 31.4731 120.784 31.4522 120.413 31.4131C120.043 31.374 119.721 31.3192 119.452 31.2566V30.6488C119.721 30.714 120.04 30.7662 120.413 30.8053C120.784 30.847 121.162 30.8653 121.539 30.8653C122.946 30.8653 124.063 30.5444 124.884 29.9053C125.704 29.2662 126.116 28.3714 126.116 27.2235C126.116 26.3809 125.886 25.6427 125.428 25.0009C124.968 24.3618 124.155 23.6522 122.985 22.8775L121.61 21.954C120.091 20.934 119.333 19.6948 119.333 18.2392C119.333 17.1227 119.759 16.2227 120.614 15.5444C121.469 14.8635 122.607 14.5244 124.031 14.5244C124.3 14.5244 124.583 14.5401 124.886 14.574C125.186 14.6079 125.455 14.647 125.692 14.6966V15.2783C125.218 15.1662 124.656 15.1088 124.007 15.1088C122.757 15.1088 121.769 15.3853 121.04 15.9331C120.312 16.4835 119.948 17.2609 119.948 18.2627C119.948 18.9253 120.103 19.5018 120.411 19.987C120.718 20.4722 121.237 20.9575 121.966 21.4427L123.341 22.3662C124.196 22.9322 124.871 23.467 125.37 23.9679C125.869 24.4688 126.225 24.9801 126.438 25.4966C126.651 26.0157 126.758 26.5896 126.758 27.2209C126.758 28.5488 126.295 29.587 125.37 30.3409V30.3435Z" fill="#146C94" stroke="#146C94" strokeWidth="2" strokeMiterlimit="10"/>
                </g>
                <path d="M0.00397585 7.99689C9.87204 5.91513 20.086 4.84158 30.3159 5.01895C33.7192 5.05629 37.1503 5.23988 40.5179 5.45148C54.0119 6.42857 67.4384 6.69929 81 6.77708C67.5457 8.18982 53.837 8.24272 40.3231 7.3123C36.9516 7.06648 33.6238 6.88288 30.2522 6.78331C25.2188 6.61527 20.1695 6.64017 15.1361 6.8611C10.0669 7.06025 5.06126 7.6017 0 8L0.00397585 7.99689Z" fill="#146C94"/>
                <path d="M51 4.58102C63.6222 -0.151368 78.5026 -1.19003 92.1439 1.33903C95.5653 2.0243 100.605 3.09062 104.019 3.82814C107.156 4.51648 110.226 5.17103 113.439 5.56744C118.252 6.21891 123.092 6.48933 128 6.41251C119.886 7.52493 111.441 6.98716 103.487 5.39228C100.062 4.76232 95.0457 3.81277 91.6483 3.13364C78.1868 0.662971 64.1937 1.38205 51 4.58102Z" fill="#146C94"/>
                <defs>
                  <clipPath id="clip0_330_59">
                    <rect width="126" height="18" fill="white" transform="translate(1 14)"/>
                  </clipPath>
                </defs>
              </svg>
              </Link>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            color="inherit"
            aria-label={open ? "close drawer" : "open drawer"}
            onClick={toggleDrawer}
            sx={{
              ml: 2,
              transition: "color 0.3s",
              color: "#146C94",
              "&:hover": {
                color: "#19A7CE",
              },
            }}
          >
            {open ? <CloseIcon sx={{ fontSize: 40 }} /> : <MenuIcon sx={{ fontSize: 40 }} />}
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer}
        sx={{
          width: 240,
          flexShrink: 0,
          zIndex: (theme) => theme.zIndex.drawer,
          "& .MuiDrawer-paper": {
            width: 240,
            zIndex: (theme) => theme.zIndex.drawer, 
          },
          "@media (max-width: 600px)": {
            "& .MuiDrawer-paper": {
              width: "100%",
            },
          },
        }}
      >
        <List sx={{ marginTop: '8vh' }}>
          {[
            { to: "/", icon: <HomeIcon />, text: "HOME" },
            { to: "/sell-your-car", icon: <AttachMoneyIcon />, text: "SELL YOUR CAR" },
            { to: "/vehicles", icon: <AttachMoneyIcon />, text: "BUY A CAR" },
            { to: "/faq", icon: <HelpOutlineIcon />, text: "FAQs" },
            // { to: "/meet-the-team", icon: <MeetTeamIcon />, text: "ABOUT US" },
          ].map((item, index) => (
            <ListItemButton
              key={index}
              component={Link}
              to={item.to}
              onClick={
                item.onClick
                  ? (e) => {
                      item.onClick();
                      toggleDrawer();
                    }
                  : toggleDrawer
              }
              sx={{
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                backgroundColor: isActive(item.to) ? "#146C94" : "transparent",
                transition: "background-color 0.3s, color 0.3s",
                padding: { xs: '16px', sm: '8px' },
                "@media (max-width: 600px)": {
                  justifyContent: 'center',
                },
                "& .MuiListItemIcon-root": {
                  color: isActive(item.to) ? "#fff" : "#146C94",
                  opacity: 0.8,
                },
                "&:hover": {
                  backgroundColor: "#146C94",
                  "& .MuiListItemIcon-root": {
                    color: "#fff",
                    opacity: 1,
                  },
                  "& .MuiListItemText-primary": {
                    color: "#fff",
                    opacity: 1,
                  },
                },
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  className: "MuiListItemText-primary",
                  sx: {
                    color: isActive(item.to) ? "#fff" : "#146C94",
                    opacity: isActive(item.to) ? 1 : 0.8,
                    textAlign: 'center', 
                    fontSize: { xs: '14px', sm: '16px' }, 
                  }
                }}
                primary={item.text}
              />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default AppLayout;