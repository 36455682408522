import React from "react";
import "./../style/meet-team-section.css"; 
import teamImage from "./../images/person.webp";

class MeetTheTeam extends React.Component {
  render() {
    return (
      <div>
      
      <section className="meet-team-section">
        <h1 className="meet-team-title">Meet the Team</h1>
        <h3>Canterbury</h3>
        {/* divider */}
        <div className="divider"></div>
        <div className="row">
          <img src={teamImage} alt="Team Member 1" className="team-image" loading="lazy" />
          <div className="team-text">
          <h4>Jack Swift</h4>
          <h5>Canterbury General Manager</h5>
          <p>Jack Swift is well-organized, kind, and considerate. He loves to see customers leave with a smile on their face and a great car to drive away in. Jack is dedicated to his work and strives to excel within the community.</p>
          </div>
        </div>
        <h3>Wellington</h3>
        <div className="divider"></div>
        <div className="row reverse">
          <img src={teamImage} alt="Team Member 1" className="team-image" loading="lazy" />
          <div className="team-text">
          <h4>Kyle Swift</h4>
          <h5>Wellington General Manager</h5>
          <p>Kyle is a valuable member of our team, offering kindness and patience to ensure every customer is well taken care of. Kyle loves to chat and ask the right questions so that our customers get exactly what they need. Kyle is also very easy-going and loves giving people a great deal.</p>
          </div>
        </div>
        <div className="row">
          <img src={teamImage} alt="Team Member 1" className="team-image" loading="lazy" />
          <div className="team-text">
          <h4>Harry Ludemann</h4>
          <h5>Trades Expert & Online Management</h5>
          <p>Harry is a crucial part of our team and provides his expertise with online trades, finances, and social networking. Harry is dedicated to sourcing only the best cars so that we stay on top of our inventory and ensure we never trade lemons! Harry is a hardworking member of our team and is always happy to help.</p>
          </div>
        </div>
      </section>
      </div>
    );
  }
}

export default MeetTheTeam;
