import React, { useState, useRef, useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore'; 
import { db, storage } from '../firebase';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import './../style/sell-your-car.css';
import Sidebar from "./../components/sidebar";

function SellCarForm() {
  const [formData, setFormData] = useState({
    make: '',
    model: '',
    regoNumber: '',
    odometer: '',
    year: '',
    modified: false,
    modifiedDetails: '',
    mechanicalIssues: '',
    mechanicalDetails: '',
    cosmeticIssues: false,
    cosmeticDetails: '',
    photos: [],
    sell: false,
    trade: false,
    both: false,
    price: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    preferredContact: '',
    branch: '',
    acceptTerms: false,
    priceNegotiable: false
  });

  const [currentSection, setCurrentSection] = useState(1);
  const [errors, setErrors] = useState({});
  const formTopRef = useRef(null); // Create a ref to the top of the form
  const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);
  
  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : (type === 'file' ? files : value)
    }));

    if (name === 'sell') {
      setFormData((prevData) => ({
        ...prevData,
        trade: false,
        both: false
      }));
    } else if (name === 'trade') {
      setFormData((prevData) => ({
        ...prevData,
        sell: false,
        both: false
      }));
    } else if (name === 'both') {
      setFormData((prevData) => ({
        ...prevData,
        sell: false,
        trade: false
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.regoNumber) {
      newErrors.regoNumber = 'Registration number is required';
      setCurrentSection(1);
    }
    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
      setCurrentSection(3);
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const uploadPhoto = async (file) => {
    const uniqueFileName = `${uuidv4()}-${file.name}`;
    const storageReference = storageRef(storage, `photos/${uniqueFileName}`);
    await uploadBytes(storageReference, file);
    return getDownloadURL(storageReference);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const photoUrls = await Promise.all(
          Array.from(formData.photos).map(file => uploadPhoto(file))
        );
  
        await addDoc(collection(db, 'carSubmissions'), {
          ...formData,
          photos: photoUrls
        });
  
        // Trigger Google Analytics conversion event
        window.gtag('event', 'conversion_event_submit_lead_form', {
          value: formData.price || 0,
          method: formData.preferredContact,
        });
  
        window.location.href = "/success-submission";
      } catch (error) {
        console.error('Error submitting the form', error);
      }
    }
  };
  
  const nextSection = () => {
    setCurrentSection(currentSection + 1);
    formTopRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to the top of the form
  };

  const prevSection = () => {
    setCurrentSection(currentSection - 1);
    formTopRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to the top of the form
  };

  return (
    <>
      <div ref={topRef}>
        <Sidebar />
      </div>
      <div className="sell-car-page">
        <h1 className='sell-title'>Sell Your Car</h1>
        <div className="sell-car-form">
          <p className='centered'>
            Ready to get the best offer for your car? Our quick and easy process ensures you get a competitive quote. Whether you're looking to sell, trade, or both, we're here to offer you the best deal possible. Fill out the form below to receive your personalized offer. If you accept, we’ll handle the rest—no hassle, no pressure, just a great deal!
          </p>
          <form onSubmit={handleSubmit} ref={formTopRef}> {/* Attach the ref to the form */}
            {currentSection === 1 && (
              <>
                <div className='divider space'></div>
                <label>
                  Make:
                  <input type="text" name="make" value={formData.make} onChange={handleChange} />
                </label>
                <label>
                  Model:
                  <input type="text" name="model" value={formData.model} onChange={handleChange} />
                </label>
                <label>
                  Registration*:
                  <input type="text" name="regoNumber" value={formData.regoNumber} onChange={handleChange} maxLength="8" />
                  {errors.regoNumber && <span className="error">{errors.regoNumber}</span>}
                </label>
                <label>
                  Odometer (km):
                  <input type="number" name="odometer" value={formData.odometer} onChange={handleChange} max="6" />
                </label>
                <label>
                  Year:
                  <input type="number" name="year" value={formData.year} onChange={handleChange} max="4" maxLength="4" />
                </label>
              </>
            )}

            {currentSection === 2 && (
              <>
                <h3>Has the vehicle been modified:</h3>
                <label>
                  Has the vehicle been modified?
                  <input type="checkbox" name="modified" checked={formData.modified} onChange={handleChange} />
                </label>
                {formData.modified && (
                  <label>
                    Details of modifications:
                    <input type="text" name="modifiedDetails" value={formData.modifiedDetails} onChange={handleChange} />
                  </label>
                )}
                <h3>Is there any mechanical issues you’re aware of?</h3>
                <label>
                  Are there any mechanical issues?
                  <input type="checkbox" name="mechanicalIssues" value={formData.mechanicalIssues} onChange={handleChange} />
                </label>
                {formData.mechanicalIssues && (
                  <label>
                    Details of mechanical issues:
                    <input type="text" name="mechanicalDetails" value={formData.mechanicalDetails} onChange={handleChange} />
                  </label>
                )}
                <h3>Is there any cosmetic issues you’re aware of?</h3>
                <label>
                  Are there any cosmetic issues?
                  <input type="checkbox" name="cosmeticIssues" value={formData.cosmeticIssues} onChange={handleChange} />
                </label>
                {formData.cosmeticIssues && (
                  <label>
                    Details of cosmetic issues:
                    <input type="text" name="cosmeticDetails" value={formData.cosmeticDetails} onChange={handleChange} />
                  </label>
                )}
                <div style={{ width: '100%' }}>
                  <h3>Add Photos: 10 is need for an accurate appraisal</h3>
                  <input type="file" name="photos" className='photoInput' multiple onChange={handleChange} />
                </div>

                <div className="form-group sell-car-type">
                  <label>Want to sell or trade the car?</label>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="sell"
                        checked={formData.sell}
                        onChange={handleChange}
                      />
                      Sell
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="trade"
                        checked={formData.trade}
                        onChange={handleChange}
                      />
                      Trade
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="both"
                        checked={formData.both}
                        onChange={handleChange}
                      />
                      Both
                    </label>
                  </div>
                </div>
                <div style={{ width: '100%' }}></div>
                <h3>How much are you want for your car?</h3>
                {!formData.priceNegotiable && 
                  <label>
                    Desired Price:
                    <input type="text" name="price" value={formData.price} onChange={handleChange} />
                  </label>}
                <label>
                  Or:
                  <label>
                    <input
                      type="checkbox"
                      name="priceNegotiable"
                      checked={formData.priceNegotiable}
                      onChange={handleChange}
                    />
                    Unsure
                  </label>
                </label>
              </>
            )}

            {currentSection === 3 && (
              <>
                <div className='divider space'></div>
                <label>
                  First Name:
                  <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
                </label>
                <label>
                  Last Name:
                  <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
                </label>
                <label>
                  Phone Number*:
                  <input type="tel" name="phone" value={formData.phone} onChange={handleChange} />
                  {errors.phone && <span className="error">{errors.phone}</span>}
                </label>
                <label>
                  Email:
                  <input type="email" name="email" value={formData.email} onChange={handleChange} />
                </label>
                <div className='divider space'></div>
                <label>
                  Preferred Contact Method:
                  <select name="preferredContact" value={formData.preferredContact} onChange={handleChange}>
                    <option value="">Select...</option>
                    <option value="text">Text Message</option>
                    <option value="call">Phone Call</option>
                    <option value="email">Email</option>
                  </select>
                </label>
                <label>
                  Preferred Branch:
                  <select name="branch" value={formData.branch} onChange={handleChange}>
                    <option value="">Select Branch...</option>
                    <option value="christchurch">Christchurch</option>
                    <option value="wellington">Wellington</option>
                  </select>
                </label>
                <label>
                  <a href="/privacy-policy" target="_blank" aria-label="privacy-policy" className="privacy-hyperlink">Privacy Policy</a>
                </label>
                <label className='special-label'>
                  <input type="checkbox" name="acceptTerms" className='space' checked={formData.acceptTerms} onChange={handleChange} required />
                  I have read and understand all contents of this page.
                </label>
                <button type="submit">Submit</button>
              </>
            )}

            <div className="navigation-buttons">
              {currentSection > 1 && <button type="button" onClick={prevSection}>Previous</button>}
              {currentSection < 3 && <button type="button" onClick={nextSection}>Next</button>}
            </div>

          </form>
          <div className="section-indicator">
            {[1, 2, 3].map(section => (
              <span
                key={section}
                className={`dot ${currentSection === section ? 'active' : ''}`}
                onClick={() => {
                  setCurrentSection(section);
                  formTopRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to the top of the form when a section indicator is clicked
                }}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default SellCarForm;
