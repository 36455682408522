import React from "react";
import "./../style/privacy.css"; 

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div>
        <h1 className="privacy-title">Privacy Policy</h1>
        <div className="privacy-section">
          <div className="privacy-list">
            <p>
              We collect personal information from you, including information about your:
              <ul>
                <li>Name</li>
                <li>Vehicle information</li>
                <li>City of residence</li>
                <li>Contact information</li>
                <li>Interactions with us</li>
              </ul>
            </p>
            <br/>
            <p>
              We collect your personal information in order to:
              <ul>
                <li>Provide goods and services to our clients</li>
                <li>Stay in contact with you regarding promotions and updates</li>
              </ul>
            </p>
            <br/>
            <p>
              Besides our staff, we share this information with:
              <ul>
                <li>Suppliers in order to deliver goods to clients</li>
                <li>Third-party service providers (such as analytics providers) to help us improve our services and your experience on our site</li>
              </ul>
            </p>
            <br/>
            <p>
              We may use cookies and similar technologies to enhance your experience on our site. Cookies help us understand how you interact with our content and improve your browsing experience. For more information about the cookies we use and your options regarding cookie preferences, please see our Cookie Policy.
            </p>
            <br/>
            <p>
              Our website uses Google Analytics, a service which transmits website traffic data to Google servers. Google Analytics does not identify individual users or associate your IP address with any other data held by Google. We use reports provided by Google Analytics to help us understand website traffic and webpage usage. By using our website, you consent to the processing of data about you by Google in the manner described in Google's Privacy Policy and for the purposes set out above. You can opt out of Google Analytics if you disable or refuse the cookie, disable JavaScript, or use the opt-out service provided by Google.
            </p>
            <br/>
            <p>
              We keep your personal information until the exchange is complete. Additionally, we may store your email address to keep you informed about future promotions and updates. If you wish to unsubscribe from these communications, please contact us at <a href="mailto:Swiftbrosautomotive@gmail.com">Swiftbrosautomotive@gmail.com</a> or 021-114-3487.
            </p>
            <br/>
            <p>
              Providing some information is optional. If you choose not to enter personal information, we may be unable to provide you with products or services.
            </p>
            <br/>
            <p>
              You have the right to ask for a copy of any personal information we hold about you, and to ask for it to be corrected if you think it is wrong. If you’d like to ask for a copy of your information, or to have it corrected, please contact us at <a href="mailto:Swiftbrosautomotive@gmail.com">Swiftbrosautomotive@gmail.com</a>, or 021-114-3487.
            </p>
            <br/>
            <p>
              We are committed to ensuring the security of your personal information. We implement various security measures to protect your data from unauthorized access, alteration, disclosure, or destruction.
            </p>
            <br/>
            <p>
              We retain your personal information only for as long as necessary to fulfill the purposes outlined in this policy, unless a longer retention period is required or permitted by law.
            </p>
            <br/>
            <p>
              Our site may include links to third-party websites or services. Please be aware that we are not responsible for the privacy practices of these third parties. We encourage you to read their privacy policies before providing any personal information.
            </p>
            <br/>
            <p>
              This Privacy Policy may be updated from time to time. We will notify you of any significant changes by posting the new policy on this page. Your continued use of our site after any changes have been made constitutes your acceptance of the updated policy.
            </p>
            <br/>
            <br/>
            <p>
              <strong>*Disclaimer:</strong> The form available on this site labeled “SELL A CAR” is for an estimation, not a quote. This form is to be completed by the registered owner of the vehicle with the intention of selling the vehicle. If there is information regarding the condition of the vehicle that is missing or withheld, this may affect the price Swift Bros Automotive will pay for the vehicle. All values presented by Swift Bros Automotive are on the condition that the vehicle is in the stated condition, and a viewing of the car can be completed in person by Swift Bros Automotive. No transactions will be made between Swift Bros Automotive and the registered owner of the vehicle without the registered owner's approval and acceptance of the agreed value.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
