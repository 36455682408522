import React from "react";

class TermsAndConditions extends React.Component {
  render() {
    return (
      <div>
        <h1 className="privacy-title">Terms and Conditions</h1>
        <div className="privacy-section">
          <div className="privacy-list">
            <h2>User Responsibilities</h2>
            <p>
              By accessing and using our website, you agree to comply with all applicable laws and regulations. You are responsible for ensuring that any information you provide to us is accurate and up to date. You must not use our website for any unlawful purpose, or in any way that could damage our reputation or the reputation of others.
            </p>
            <br/>
            <h2>Disclaimers of Liability</h2>
            <p>
              We make every effort to ensure that the information on our website is accurate and up to date. However, we do not warrant the accuracy or completeness of the content provided on our website, and we are not responsible for any errors or omissions. Use of our website is at your own risk, and we shall not be liable for any damages arising from the use or inability to use our site.
            </p>
            <br/>
            <h2>Intellectual Property Rights</h2>
            <p>
              All content on this website, including text, graphics, logos, and images, is the property of Swift Bros Automotive or its content suppliers and is protected by international copyright laws. You may not reproduce, distribute, or create derivative works from any content on this website without our express written permission. Any third-party content used on this website is done so with permission and remains the property of the respective owners.
            </p>
            <br/>
            <h2>Consumer Guarantees Act (CGA) Compliance</h2>
            <p>
              All goods and services offered on this website comply with the Consumer Guarantees Act 1993. We guarantee that the vehicles sold are of acceptable quality and fit for the purpose described. If a vehicle does not meet these standards, you may be entitled to a repair, replacement, or refund.
            </p>
            <br/>
            <h2>Fair Trading Act Compliance</h2>
            <p>
              We ensure that all representations and claims made on this website are truthful and not misleading. This includes accurate descriptions of vehicles, prices, and promotions. We comply with the Fair Trading Act 1986 to protect consumers from false or misleading conduct.
            </p>
            <br/>
            <h2>Electronic Communications Compliance</h2>
            <p>
              By providing your email address, you consent to receive communications from us. These communications may include promotional offers and updates about our services. You may opt out of receiving these communications at any time by contacting us at <a href="mailto:Swiftbrosautomotive@gmail.com">Swiftbrosautomotive@gmail.com</a> or 021-114-3487.
            </p>
            <br/>
            <h2>Health and Safety</h2>
            <p>
              If you visit our premises for a test drive or other purposes, you must comply with our health and safety guidelines. We take all reasonable steps to ensure your safety while on our premises, and we expect visitors to follow all safety instructions provided by our staff.
            </p>
            <br/>
            <h2>Accessibility</h2>
            <p>
              We are committed to making our website accessible to all users, including those with disabilities. If you have any difficulty accessing any part of our site, please contact us at <a href="mailto:Swiftbrosautomotive@gmail.com">Swiftbrosautomotive@gmail.com</a> or 021-114-3487, and we will do our best to provide the information you need in an accessible format.
            </p>
            <br/>
            <h2>Dispute Resolution</h2>
            <p>
              Any disputes arising from the use of our website or the purchase of our products or services will be handled through mediation in accordance with New Zealand law. If mediation is unsuccessful, the dispute may be taken to the appropriate New Zealand court.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsAndConditions;
