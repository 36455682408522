import React from "react";
import "./../style/404.css";
import { FaExclamationTriangle } from "react-icons/fa";

class ErrorPage extends React.Component {
  render() {
    return (
      <div className="error-container">
        <div className="error-content">
          <FaExclamationTriangle className="error-icon" />
          <h1 className="error-title">Oops! Page Not Found</h1>
          <p className="error-message">
            Looks like you’ve taken a wrong turn. The page you’re looking for doesn’t exist.
          </p>
          <button className="error-button" onClick={() => window.location.href = '/home'}>
            Go Back Home
          </button>
        </div>
      </div>
    );
  }
}

export default ErrorPage;